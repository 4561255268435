import "styles/print.css";
import React, { useRef, useState, useEffect } from "react";
import ThaiBahtText from "thai-baht-text";
import { billData } from "mocks/billData";
import QRCode from "qrcode";
import moment from "moment";

import { NumericFormat } from "react-number-format";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';

import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CommentIcon from '@mui/icons-material/Comment';
import thaiDate from "utils/thaiDate";

export function InvPrint({ innerRef, values, invoice }) {
  const maxRowPerPage = 8;

  const [qtyUnit, setQtyUnit] = useState(0);
  const [prow, setProw] = useState();
  const [page, setPage] = useState();

  const [priceUnit, setPriceUnit] = useState(0);

  const [tmpRow, setTmpRow] = useState(0);


  const loadData = (prodItems) => {

    const data = prodItems;
    let p = Math.ceil(data.length / maxRowPerPage);

    setPage(p);
    let arr = [];
    for (var i = 1; i <= p; i++) {
      let dataPage = data.slice(
        (i - 1) * maxRowPerPage,
        (i - 1) * maxRowPerPage + maxRowPerPage
      );

      if (dataPage.length < maxRowPerPage) {
        setTmpRow(maxRowPerPage - dataPage.length);
      }

      // const totals = dataPage.reduce(
      //   (acc, item) => {
      //     acc.totalQty += item.qty;
      //     acc.totalPriceBeforeTax += item.price_before_tax;
      //     return acc;
      //   },
      //   { totalQty: 0, totalPriceBeforeTax: 0 }
      // );
      // dataPage.totals = totals;
      arr.push(dataPage);
    }
    setProw(arr);
  };

  useEffect(() => {
    loadData([invoice])
  }, [invoice]);

  useEffect(() => {
  }, []);

  return (
    <div ref={innerRef}>
      {invoice && (
        <PagePrint
          pageCur={page}
          pageAll={1}
          invoice={invoice}
          tmpRow={tmpRow}
          key={1}
        />
      )}

    </div>
  );
}

const PagePrint = ({
  pageCur,
  pageAll,
  invoice,
  tmpRow,
}) => {
  return (
    <div
      className="text-[10.5px] text-[#2C2C2C] mb-1"
      style={{
        width: "210mm",
        height: "297mm",
        padding: "5px 30px",
        margin: "0",
        background: "white",
        position: "relative",
      }}
    >
      <div className=" flex text-[11.5px] items-start mb-3">
        <div className="mr-auto mt-3 ">

          <div className="mb-3">
            <img src={invoice.shop.logo_image} className="w-[91px] h-[91px]  object-cover rounded-[50%]" />
          </div>
          <div className="flex items-start">
            <div className=" pr-[25px] w-[350px]">
              <div className="flex mb-2">
                <div className="flex-none w-[90px]">
                  หน่วยงาน :
                </div>
                <div className="grow text-[#000] ">{invoice.shop.name} </div>
              </div>
              <div className="flex mb-2">
                <div className="flex-none w-[90px]">ที่อยู่ :</div>
                <div className="grow text-[#000]">
                  {invoice.shop.full_address}
                </div>
              </div>
              <div className="flex mb-2">
                <div className="flex-none w-[90px]">เลขที่ภาษี :</div>
                <div className="grow text-[#000]">{invoice.shop.shopBookBank[0]?.tax_number}
                  {
                    invoice.shop.shopBookBank[0]?.taxBranch?.name ? `(
                  ${invoice.shop.shopBookBank[0]?.tax_branch_id == 1 ?
                        invoice.shop.shopBookBank[0]?.taxBranch?.name :
                        (invoice.shop.shopBookBank[0]?.taxBranch?.name || '') + 'ที่ ' + (invoice.shop.shopBookBank[0]?.branch_vat_code || '')
                      }
                  )`
                      : ''
                  }
                </div>
              </div>
            </div>
            <div className=" mr-3">
              <div className="mb-1">ติดต่อกลับที่ : {invoice.shop.userShop[0].first_name} {invoice.shop.userShop[0].last_name}</div>
              <div className="mb-1"> <LocalPhoneIcon className="text-[14px] mt-[-1px] mr-2" /> {invoice.shop.phone_number}</div>
              {/* <div className="mb-1"> <EmailIcon className="text-[14px] mt-[-1px] mr-2" /> miame@gmail.com</div> */}
              <div className="mb-1"> <LanguageIcon className="text-[14px] mt-[-1px] mr-2" /> {invoice.shop.link}</div>
            </div>
          </div>
          <hr className="my-3 border-[#000] border-[1.5px]" />
          <div className="flex items-start">
            <div className=" pr-[25px] w-[350px]">
              <div className="flex mb-2">
                <div className="flex-none w-[90px]">
                  ขอแจ้งหนี้แก่ :
                </div>
                <div className="grow text-[#000] ">การท่องเที่ยวแห่งประเทศไทยสำนักงานเชียงราย</div>
              </div>
              <div className="flex mb-2">
                <div className="flex-none w-[90px]">ที่อยู่ :</div>
                <div className="grow text-[#000]">
                  448 / 16 ถ.สิงหไคล ตำบลเวียง อำเภอเมืองเชียงราย จังหวัดเชียงราย 57000
                </div>
              </div>
              <div className="flex mb-2">
                <div className="flex-none w-[90px]">เลขที่ภาษี :</div>
                <div className="grow text-[#000]">0994000165277 ( สาขาที่ 0011 )</div>
              </div>
            </div>
            <div className=" mr-3">

              <div className="mb-1"> <LocalPhoneIcon className="text-[14px] mt-[-1px] mr-2" /> 053-744674-5</div>
              <div className="mb-1"> <EmailIcon className="text-[14px] mt-[-1px] mr-2" /> partner.tatchrai@gmail.com</div>
              <div className="mb-1"> <LanguageIcon className="text-[14px] mt-[-1px] mr-2" /> www.tatchiangrai.com </div>

            </div>
          </div>
        </div>
        <div className="flex-none">
          <div className="text-right mb-7">
            {/* แผ่นที่ {pageCur}/{pageAll} */}
          </div>
          <div className="text-right mb-4" >(ต้นฉบับ) </div>
          <div className="flex items-center">

            <div className="   overflow-hidden">

              <div className="text-[32px] py-1 text-right text-[#3B96EF]">
                ใบแจ้งหนี้
              </div>
              <div className="bg-[#DDEEFE] py-2 px-2 text-[#2C2C2C] rounded">
                <div className="px-2 mb-1 ">
                  <span>เลขที่เอกสาร : </span>
                  <span>{invoice.code}</span>
                </div>
                <div className="px-2 ">
                  <span>วันที่ออกเอกสาร  : </span>
                  <span>{thaiDate(invoice.issued_at)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="py-3">
        <table className="w-full  border-collapse border-slate-700 table-print">
          <thead>
            <tr>
              <th
                valign="middle"
                width="70%"
                style={{ width: "70%" }}
                className=" bg-[#DDEEFE]  text-left font-normal"
              >
                รายการ
              </th>
              <th
                valign="middle"
                width="1%"
                className=" bg-[#DDEEFE] text-center font-normal"
              >
                จำนวน
              </th>
              <th
                valign="middle"
                width="12%"
                className=" bg-[#DDEEFE] text-right font-normal"
              >
                ราคาหน่วยละ
              </th>
              <th
                valign="middle"
                width="10%"
                className=" bg-[#DDEEFE] text-nowrap text-right "
              >
                ราคารวม
              </th>


            </tr>
          </thead>
          <tbody>
            <tr style={{ height: "30px" }} >
              <td className=" ellipsis text-left">
                <div>{invoice.document_subject}</div>
              </td>
              <td className=" pl-2 text-center ellipsis">
                <div>{(invoice.couponPerUnit == 0) ? 0 : (invoice.total_remain / invoice.couponPerUnit).toFixed(1)}</div>
              </td>
              <td className="text-right" style={{ maxWidth: "100px" }}>
                <div>{invoice.couponPerUnit}</div>
              </td>
              <td className=" text-right ellipsis">
                <NumericFormat
                  displayType="text"
                  thousandSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  value={invoice.total_remain}
                />
              </td>


            </tr>

            {pageCur == pageAll
              ? [...Array(tmpRow)].map((x, i) => (
                <tr style={{ height: "42px" }} key={`tmp${i}`}>
                  <td className=" ellipsis text-left"></td>
                  <td className=" ellipsis"></td>
                  <td className=" ellipsis"></td>
                  <td className=" ellipsis"></td>
                </tr>
              ))
              : ""}
          </tbody>

        </table>
      </div>

      <hr className="my-3 border-[#000] border-[1.5px]" />

      <div className="flex w-[100%] text-[11px] text-[#2C2C2C]">
        <div className="mr-[30px]"> <ContentPasteIcon className="text-[12px] mt-[-1px]" /> สรุป</div>
        <div className="grow mr-[30px]">
          <div className="flex mb-1">
            <div className="mr-auto">มูลค่ารายการภาษีมูลค่าเพิ่มอัตรา 7 %</div>
            <div className="text-end">{invoice.shop.shopBookBank[0]?.vat_type_id == 1 ? invoice.total_remain : parseFloat(Number(invoice.total_remain) - (Number(invoice.total_remain) * (7 / 100))).toFixed(2)}</div>
          </div>
          <div className="flex  mb-1">
            <div className="mr-auto">ภาษีมูลค่าเพิ่มรวม</div>
            <div className="text-end">{invoice.shop.shopBookBank[0]?.vat_type_id == 1 ? 0 : parseFloat((Number(invoice.total_remain) * (7 / 100))).toFixed(2)} บาท</div>
          </div>
          <div className="flex">
            <div className="mr-auto">จำนวนเงินทั้งสิ้น</div>
            <div className="text-end">{invoice.total_remain} บาท</div>
          </div>

        </div>
        <div className="">
          <div className="bg-[#DDEEFE] h-full w-[250px] rounded px-4 py-3">
            <div className="flex items-end mb-2">
              <div className="mr-auto">จำนวนเงินที่ชำระ</div>
              <div> <span className="text-[16px] text-[#0065B4] mr-2 font-bold">{invoice.total_remain}</span>  <span>บาท</span></div>

            </div>
            <div className="text-end text-[10.5px] text-[#2C2C2C] font-semibold">
              ( {ThaiBahtText(invoice.total_remain)} )
            </div>
          </div>
        </div>

      </div>

      <hr className="my-3 border-[#000] border-[1.5px]" />

      <div className="flex w-[100%] text-[11px] text-[#2C2C2C]">
        <div className="mr-[30px]"> <LocalAtmIcon className="text-[12px] mt-[-1px]" /> ชำระเงิน</div>
        <div className="grow mr-[30px]">
          <div className="flex mb-1">
            <div className="mr-auto font-bold text-[12px]">{invoice.shop.shopBookBank[0]?.bank.name}</div>
          </div>
          <div className="flex  mb-1">
            <div className="mr-auto">{invoice.shop.shopBookBank[0]?.accountType?.name}  <span className="text-[#0065B4]">{invoice.shop.shopBookBank[0]?.account_number}</span> </div>
          </div>
          <div className="flex">
            <div className="mr-auto">{invoice.shop.shopBookBank[0]?.account_name}</div>
          </div>
        </div>
      </div>

      <hr className="my-3 border-[#000] border-[1.5px]" />

      <div className=" w-[100%] text-[11px] text-[#2C2C2C]">
        <div className="mr-[30px] mb-[50px]"> <CommentIcon className="text-[12px] mt-[-1px]" /> หมายเหตุ</div>
        <div className="flex justify-around mb-[70px]">
          <div className=" mb-1">
            <div className="">ผู้แจ้งหนี้</div>
          </div>
          <div className="  mb-1">
            <div className="">ตราประทับผู้แจ้งหนี้ (ถ้ามี)  </div>
          </div>
          <div className="">
            <div className="">ผู้รับเอกสาร</div>
          </div>
          <div className="">
            <div className="">ตราประทับ </div>
          </div>
        </div>

        <div className="flex justify-around">
          <div></div>
          <div></div>
          <div className="mb-2">วันที่ _____/_____/_____</div>
          <div></div>
        </div>
      </div>

    </div>
  );
};
